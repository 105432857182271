import { Component, Prop, Mixins } from 'vue-property-decorator'
import tableBaseMixin from './tableBaseMixin'

type RenderType =
  | 'text'
  | 'image'
  | 'datetime'
  | 'tag'
  | 'state'
  | 'link'
  | 'customLink'
  | 'complex'
  | 'actions'
  | 'template'
  | 'custom'
  | 'function'

const RENDER_TYLES: RenderType[] = [
  'text',
  'image',
  'datetime',
  'tag',
  'state',
  'link',
  'customLink',
  'complex',
  'actions',
  'template',
  'custom',
  'function',
]

@Component({
  name: 'AmTableColumnMixin',
})
export default class AmTableColumnMixin extends Mixins(tableBaseMixin) {
  @Prop(String) readonly prop?: string
  @Prop(String) readonly label?:
    | string
    | {
        value: string
        i18nKey?: string
      }
  @Prop(String) readonly width?: string
  @Prop(String) readonly minWidth?: string
  @Prop(String) readonly type?: string
  @Prop(String) readonly align?: string
  @Prop(Boolean) readonly showOverflowTooltip?: boolean
  @Prop(String) readonly customColunmnDesc?: string
  @Prop({ type: Boolean, default: true }) readonly resizable?: boolean
  @Prop(String) readonly fixed?: boolean | string
  @Prop({ type: Boolean, default: false }) readonly fixedWidth?: boolean
  @Prop([Boolean, String]) readonly sortable?: boolean | string
  @Prop([Boolean, String]) readonly hideColumn?: boolean | string
  @Prop({ type: String, default: 'text' }) readonly renderType!: RenderType
  @Prop(String) readonly renderTemplate?: string
  @Prop(Boolean) readonly actionDivider?: boolean
  @Prop(Array) readonly actions?: any[]
  @Prop(Object) readonly builtInTemplateConfig?: Record<string, any>

  // 2023-1-6 新增属性
  @Prop(String)
  readonly clearPadding?: string
  // 2023-1-6 新增属性
  @Prop({ type: Boolean, default: false })
  readonly expandContentNoPadding!: boolean

  // 2023-5-10 新增属性
  @Prop(Function) readonly renderHeader?: any
  // 2023-5-10 新增属性
  @Prop(Function) readonly render?: any
  // 2023-11-10 新增属性
  @Prop(Function) readonly formatter?: any

  get columnLabel() {
    if (typeof this.label === 'string') return this.label
    if (this.label?.i18nKey)
      return this.lowcode.i18n.get(this.label.i18nKey, this.label.value)
    return this.label?.value || ''
  }

  get isTypedRenderType() {
    return !!this.type
  }

  get isTextRenderType() {
    return (
      this.renderType === 'text' ||
      !this.renderType ||
      !RENDER_TYLES.includes(this.renderType)
    )
  }
  get isDatetimeRenderType() {
    return this.renderType === 'datetime'
  }
  get isImageRenderType() {
    return this.renderType === 'image'
  }
  get isTagRenderType() {
    return this.renderType === 'tag'
  }
  get isStateRenderType() {
    return this.renderType === 'state'
  }
  get isLinkRenderType() {
    return this.renderType === 'link'
  }
  get isCustomLinkRenderType() {
    return this.renderType === 'customLink'
  }
  get isComplexRenderType() {
    return this.renderType === 'complex'
  }
  get isActionsRenderType() {
    return this.renderType === 'actions'
  }
  get isTemplateRenderType() {
    return this.renderType === 'template'
  }
  get isCustomRenderType() {
    return this.renderType === 'custom'
  }
  get isFunctionRenderType() {
    return this.renderType === 'function'
  }

  get formattedActions() {
    if (!this.isActionsRenderType) return []
    let formattedActions: any[] = []
    let groupedActions: Record<string, any> = {}
    this.actions?.forEach(action => {
      const { group } = action
      if (!group) formattedActions.push(action)
      else {
        if (!groupedActions[group])
          groupedActions[group] = {
            title: group,
            type: 'group',
            // 分组按钮下的子按钮
            subActions: [],
          }
        let { subActions } = groupedActions[group]
        subActions.push({ title: action.title, action: action.action })
      }
    })
    return formattedActions.concat(Object.values(groupedActions))
  }

  get config() {
    return this.builtInTemplateConfig || {}
  }

  getContent(row: Record<string, any>) {
    return this.prop ? row[this.prop] : void 0
  }

  getImageSrc(row: Record<string, any>) {
    const src = this.prop ? row[this.prop] : ''
    return src ? this['lowcode']?.utils?.url2cdn(src) || src : src
  }

  getCustomRenderComponent(scope) {
    if (this.isFunctionRenderType || this.isCustomLinkRenderType) {
      return Vue.extend({
        render: h =>
          typeof this.render === 'function'
            ? this.render(h, scope)
            : h('span', this.getContent(scope.row)),
      })
    } else {
      let renderTemplate = ''
      if (this.isCustomRenderType) renderTemplate = this.renderTemplate || ''
      else if (this.isComplexRenderType)
        renderTemplate = this.config.template || ''
      if (renderTemplate) {
        renderTemplate = unescape(renderTemplate)
          .replace(/this\./g, 'rootVm.')
          // 兼容老的写法
          .replace(/\$\./g, 'rootVm.')
        const { rootVm } = this
        return Vue.extend({
          template: renderTemplate,
          data() {
            return {
              scope,
              rootVm,
            }
          },
        })
      }
    }
  }

  execButtonAction(action: string, scope: any) {
    if (!action) return
    const _rootVM = this.getRootVm(action)
    const func = _rootVM[action]
    if (typeof func !== 'function')
      throw new Error(`AmTableColumn action [${action}] is not a function!`)
    func(scope)
  }

  execDropdownMenuAction(scope: any, args: any[]) {
    const action = args[0]
    this.execButtonAction(action, scope)
  }
}
