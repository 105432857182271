














































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component({
  name: 'AmBizUploadV2',
})
export default class AmBizUploadV2 extends Vue {
  static componentName = 'AmBizUploadV2'
  @Prop() readonly defaultList!: any
  @Prop({default: 'default'}) readonly compType!: string
  // 是否开启拖拽
  @Prop({default: false}) readonly isDrag!: boolean
  //上传类型
  @Prop({ default: 'normal' }) readonly uploadType!: string
  //是否对图片开启缩略图（及私密的空间的图片也上传至公共空间，并返回可直接访问的图片地址）
  @Prop({ default: false }) openImageThumbnails!: boolean
  //是否上传到公开空间
  @Prop({ default: false }) isOpen!: boolean
  //自定义文件类型过滤，示例：.jpg,.jpeg,.gif,.png
  // @Prop() filters!: string
  //业务线source,项目引用业务组件时传入的source
  @Prop() source!: string
  //微应用编号，如ote、o2o等
  @Prop({ required: true }) appCode!: string
  @Prop({ default: 'v1' }) version!: string
  @Prop() sceneCode!: string
  //获取上传配置的类型，configKey列表
  @Prop({ required: true }) configKey!: string
  //板块名称，如知识库模块为kng
  @Prop({ required: true }) moduleName!: string
  //功能名称，如知识库模块下的视频功能为video
  @Prop({ required: true }) functionName!: string
  //是否多选
  @Prop({ default: false }) multipe!: boolean
  //是否自动上传
  @Prop({ default: true }) autoUpload!: boolean
  // 初始化完毕事件
  @Prop() readyHandler!: any
  // files过滤
  @Prop() filesFilterHandler!: any
  // 文件被添加到队列中事件
  @Prop() filesAddedHandler!: any
  //自定义开始上传事件
  @Prop() customBeforeUpload!: any
  // 文件上传速度回调
  @Prop() networkSpeedHandler!: any
  //是否启用拖拽上传
  // @Prop({ default: false }) drag!: boolean
  //上传图标
  @Prop({ default: 'upload' }) iconClass!: string
  //是否禁用上传
  @Prop({ default: false }) disabled!: boolean
  //是否需要转码，图片不转码
  @Prop({ default: true }) convert!: boolean
  // 禁止上传的文件类型，示例：.bmp,.ico
  @Prop() disableFilters!: string
  //允许上传的文件个数
  @Prop() limit!: number
  //允许上传的文件大小，单位kb
  @Prop() maxSize!: number
  //是否提示错误消息
  @Prop({ default: false }) errorTip!: boolean
  //1.0兼容模式
  @Prop() isV1!: boolean
  //是否对文件进行md5计算
  @Prop() md5!: boolean
  //文件中心票据，token替代品
  @Prop() ticket!: string
  //是否上传到私有云
  @Prop({ default: false }) isLocal!: boolean
  //私有云的服务器组id，混合式部署上传需要传入该值
  @Prop() localGroupId!: string
  //是否为业务方混合部署（在线课堂、知识库）
  @Prop({ default: false }) isBizMixDeploy!: boolean
  //布尔值true文件转码且放置私密桶 布尔值false文件不转码且放置公开桶 传入obj单独配置文件类型是否转码及桶配置（默认配置见demo transcodeOptions）
  @Prop({ type: [Object, Boolean], default: true }) transcodeOptions!: any
  //上传按钮文案
  @Prop({ default: '上传附件' }) readonly buttonText!: string
  //上传按钮大小
  @Prop({ default: 'medium' }) readonly buttonSize!: string
  //上传按钮类型
  @Prop({ default: 'primary' }) readonly buttonType!: string
  //上传文件类型
  @Prop() readonly accept!: string
  @Prop({ default: '178px' }) readonly pictureWidth!: string
  @Prop({ default: '178px' }) readonly pictureHeight!: string
  //是否展示文件列表
  @Prop({ default: true }) isShowFileList!: boolean

  // 图片上传模式下文件
  pictureUrl: string = ''
  fileList: any = []
  imageDialogVisible: boolean = false
  imageUrl: string = ''

  /**
   * 卡片上传类型线上内容
   */
  get filters() {
    return (
      this.accept ||
      '.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pps,.pdf,.zip,.rar,.jpg,.jpeg,.gif,.png,.bmp,.ico'
    )
  }

  get openTipSlot() {
    return this.$slots['tip']?.length
  }

  get uploadTypeEnable() {
    return this.compType !== 'custom'
  }

  get drag() {
    return this.uploadType === 'drag' && this.uploadTypeEnable || (!this.uploadTypeEnable && this.isDrag)
  }

  // @Watch('uploadType')
  // uploadTypeChange(nval) {
  //   this.drag = nval === 'drag' && this.uploadTypeEnable
  // }

  @Watch('defaultList', {
    deep: true,
    immediate: true,
  })
  defaultListChange(nval) {
    if (!nval) return
    if (this.uploadType === 'picture') {
      this.pictureUrl = nval
    } else {
      this.fileList = JSON.parse(JSON.stringify(nval))
    }
  }

  //初始化完毕事件
  onReady() {
    console.log('-onReady-')
     if (this.readyHandler) {
      this.readyHandler()
    }
  }

  //选择的文件大小超过后台配置的maxFileSize，回调这个方法，文件不会被加到 files-filter 的 files 里
  fileFiltered(file) {
    console.log('-fileFiltered-', file)
  }

  //用户自定义文件过滤，返回值需要是一个数组，里面保留需要添加到队列的文件
  filesFilter(files) {
    console.log('-filesFilter-', files)
    // 1. 限制上传数量， 完全不可以上传
    // 2. 限制文件大小， 超出大小的过滤
    if (files.length + this.fileList.length > this.limit) {
      this.$message.warning(`最多可上传${this.limit}个文件`)
      return []
    }

    if(this.filesFilterHandler) return this.filesFilterHandler(files)

    return files
  }

  //文件被加入到队列事件
  filesAdded(files) {
    console.log('-filesAdded-', files)
    this.filesAddedHandler && this.filesAddedHandler(files)
  }

  //某个文件开始上传事件，如果想阻止这个文件的上传，请返回 false，如果这里需要执行异步的操作，可以返回 Promise 对象
  beforeUpload(file) {
    console.log('-onBeforeUpload-', file)
    let _file = file
    if (this.customBeforeUpload) {
      _file = this.customBeforeUpload(file)
    } else {
      if (
        this.uploadType === 'picture' &&
        !this.filters.includes(file.extension)
      ) {
        this.$message.warning(`您选择的图片格式不正确`)
        return false
      }
      if (file.size / 1024 > this.maxSize) {
        this.$message.warning(`您选择的文件大小已超过允许的大小`)
        return false
      }
    }
    if (!this.fileList.filter((item: any) => item.uuid === _file.uuid).length) {
      this.fileList.push({
        id: _file.id,
        name: _file.name,
        size: _file.size,
        fileType: _file.fileType,
        fullUrl: _file.fullUrl,
        uuid: _file.uuid,
        extension: _file.extension,
        percentage: 0,
        loading: true,
      })
    }
  }

  //文件上传速度事件，回调参数（bytes：已上传字节数，time：上传用时，pendings：剩余字节数）
  onNetworkSpeed(a, b, c) {
    console.log('-onNetworkSpeed-', a, b, c)
    this.networkSpeedHandler && this.networkSpeedHandler(a, b, c)
  }

  //上传进度事件，回调参数（file：上传的文件，progress：已上传比例，event：进度事件参数）
  onProgress(file, progress, event) {
    console.log('-onProgress-', progress)
    const f = this.getFile(file.uuid)
    if (f) {
      f.percentage = progress.toFixed(2) * 100
    }
    this.$emit('progress', file, progress, event)
  }

  //文件上传成功事件
  onUploaded(file) {
    console.log('-onUploaded-', file)
    const attach = this.fileList.find(item => item.uuid === file.uuid)

    this.$set(attach, 'fullUrl', file.fullUrl)
    this.$set(attach, 'id', file.id)
    this.$set(attach, 'status', 'success')

    if (this.uploadType === 'picture') {
      this.pictureUrl = file.fullUrl
    }
    this.lowcode.log(this.fileList, 'this.fileList')
    this.$emit('uploaded', file)
    this.$emit('onFileList', this.fileList)
  }

  //队列里面的文件上传结束事件
  onComplete() {
    console.log('-onComplete-')
    this.$emit('complete')
  }

  //上传出错事件，回调参数（error：错误信息，file：上传的文件）
  onError(error, file) {
    console.log('-onError-', error, file)
    const attach = this.fileList.find(item => item.uuid === file.uuid)
    if (attach) {
      this.$set(attach, 'url', file.fullUrl || '')
      this.$set(attach, 'status', 'error')
    }

    this.$emit('onFileList', this.fileList)
  }

  getFile(uuid) {
    for (const f of this.fileList) {
      if (f.uuid === uuid) {
        return f
      }
    }
  }

  //查看文件
  download(url) {
    url && window.open(url, '_blank')
  }

  //删除文件
  deleteFile(file) {
    this.fileList = this.fileList.filter(v => v.uuid !== file.uuid)
    this.$emit('onFileList', this.fileList)
  }

  //文件预览
  preview(file) {
    if (file.fileType === 'image') {
      this.imageUrl = file.fullUrl
      this.imageDialogVisible = true
    }
  }
  //图片模式下的预览
  previewPic() {
    this.imageUrl = this.pictureUrl
    this.imageDialogVisible = true
  }
  //图片模式删除
  deletePic() {
    this.pictureUrl = ''
    this.$set(this, 'fileList', [])
    this.$emit('onFileList', this.fileList)
  }
}
