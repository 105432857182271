import { nanoid } from 'nanoid'
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import tableBaseMixin from './tableBaseMixin'

const NOT_ALLOWED_COLUMN_TYPES = ['selection', 'custom-column']

@Component({
  name: 'AmTableMixin',
})
export default class AmTableMixin extends Mixins(tableBaseMixin) {
  @Prop(Array) readonly columns!: any[]
  @Prop(Array) readonly data!: any[]
  @Prop(Array) readonly showRootNode!: boolean
  @Prop(String) readonly height?: string
  @Prop(String) readonly maxHeight?: string
  @Prop(String) readonly size?: string
  @Prop(Boolean) readonly stripe?: boolean
  @Prop(Boolean) readonly border?: boolean
  @Prop({ type: Boolean, default: true }) readonly showHeader!: boolean
  @Prop({ type: Boolean, default: true }) readonly fit!: boolean
  @Prop({ type: Boolean, default: false }) readonly columnScalable!: boolean
  @Prop({ type: Boolean, default: false }) readonly isNumber!: boolean
  @Prop({ type: Boolean, default: false })
  readonly highlightCurrentRow!: boolean
  @Prop(Boolean) readonly virtual?: boolean
  @Prop(Boolean) readonly loading?: boolean
  @Prop(Number) readonly rowHeight?: string
  @Prop(String) readonly rowKey?: string
  @Prop(Object) readonly defaultSort?: { prop?: string; order?: string }
  @Prop(Boolean) readonly selection?: boolean
  @Prop(Boolean) readonly lineDrag?: boolean
  @Prop(Boolean) readonly dropSelection?: boolean
  @Prop(Object) readonly customColumnProps?: {
    enable: boolean
    mustRender?: boolean
    showDiasbledAttributes?: boolean
    position?: 'top' | 'inline'
    cacheId?: string
    isDrawer?: boolean
    groupList?: any
    isDragable?: boolean
  }
  @Prop({ type: String, default: 'dark' }) readonly tooltipEffect!: string

  // 2023-1-16 新增属性
  @Prop(Function) readonly headerCellStyleGetter?: () => any
  @Prop(Function) readonly cellStyleGetter?: () => any
  @Prop(Function) readonly spanMethod?: () => any
  @Prop(Function) readonly selectable?: () => any

  // 2023-5-8 新增属性
  @Prop(Object) readonly treeProps?: any
  @Prop(Boolean) readonly lazy?: boolean
  @Prop(Function) readonly load?: any
  // 2023-5-12 新增属性
  @Prop(Boolean) readonly defaultExpandAll?: any
  // 2023-5-12 新增属性
  @Prop(Array) readonly expandRowKeys?: any

  // 2024-6-5 新增属性
  @Prop(Boolean) readonly radio?: boolean
  @Prop(String) readonly radioRowByKey?: string
  @Prop(String) readonly selectedRowByKey?: string

  @Prop(Boolean) readonly enableHoverRow?: boolean

  forceUpdateKey: string = ''

  /**
   * yxt-table height / maxHeight 的设置存在 bug，通过以下方式进行 hack
   * 1. 初始时 height/maxHeight = undefined，设置有效值后表格更新正确，之后将值重设为 undefined 表格将不会更新
   * 2. 如果将 height/maxHeight 默认设置为空字符串，则表格在挂载渲染时会报错（但是不影响渲染）
   */
  tableHeight?: string
  tableMaxHeight?: string
  iconClass: string = 'arrow_up'

  get tableInstance() {
    return this.$refs.tableInstance
  }

  get tableColumns() {
    if (!Array.isArray(this.columns)) return []

    // 将 columns 按照分组聚合
    let tableColumnGroups: Record<string, any> = {}
    return this.columns.reduce((tableColumns, _column) => {
      if (NOT_ALLOWED_COLUMN_TYPES.includes(_column.type)) return tableColumns

      const column = this.parseColumn(_column)
      const { group } = column
      if (!group) tableColumns.push(column)
      else {
        let groupColumn = tableColumnGroups[group]
        if (!groupColumn) {
          groupColumn = {
            id: group,
            label: group,
            type: 'group',
            children: [],
          }
          tableColumnGroups[group] = groupColumn
          tableColumns.push(groupColumn)
        }
        groupColumn.children.push(column)
      }
      return tableColumns
    }, [] as any[])
  }

  get tableData() {
    return Array.isArray(this.data) ? this.data : []
  }

  get tableCustomColumnEnable() {
    return this.customColumnProps?.enable === true
  }

  get tableCustomColumnButtonIsTop() {
    return this.customColumnProps?.position === 'top'
  }
  get tableCustomColumnProps() {
    if (!this.tableCustomColumnEnable) return void 0
    if (this.tableCustomColumnButtonIsTop) {
      return {
        ...this.customColumnProps,
        fixedButton: true,
        mustRender: true,
      }
    }

    return {
      ...this.customColumnProps,
      mustRender: true,
      fixedButton: true,
    }
  }

  get tableRowHeight() {
    if (!this.rowHeight) return void 0
    return parseInt(this.rowHeight.replace('px', ''))
  }

  get typedColumnWidth() {
    return '32'
  }

  @Watch('height')
  handleTableHeightChange(newVal) {
    this.tableHeight = newVal || ''
  }

  @Watch('maxHeight')
  handleTableMaxHeightChange(newval) {
    this.tableMaxHeight = newval || ''
  }

  @Watch('rowKey')
  @Watch('treeProps')
  @Watch('defaultExpandAll')
  @Watch('tableColumns')
  handleUpdateForceUpdateKey() {
    this.forceUpdateKey = Date.now().toString()
  }

  created() {
    this.tableHeight = this.height
    this.tableMaxHeight = this.maxHeight
  }

  parseColumn(_column: any) {
    const column = { ..._column }
    if (!column.id) column.id = nanoid()

    if (column.renderHeader) {
      const _rootVM = this.getRootVm(column.renderHeader)
      const renderHeader = _rootVM[column.renderHeader]
      if (typeof renderHeader === 'function')
        column.renderHeader = renderHeader.bind(_rootVM)
      else {
        // 尝试构建 column renderHeader function
        try {
          column.renderHeader = new Function(`return ${column.renderHeader}`)
            .apply(this.rootVm)
            .bind(this.rootVm)
        } catch (error) {
          delete column.renderHeader
        }
      }
    }

    if (column.render) {
      column.renderType = column.renderType || 'function'
      const _rootVM = this.getRootVm(column.render)
      const renderCell = _rootVM[column.render]
      if (typeof renderCell === 'function') {
        column.render = renderCell.bind(_rootVM)
      } else {
        // 尝试构建 column render function
        // 构建失败则渲染为 text
        try {
          column.render = new Function(`return ${column.render}`)
            .apply(this.rootVm)
            .bind(this.rootVm)
        } catch (error) {
          column.renderType = 'text'
          delete column.render
        }
      }
    } else if (column.renderTemplate) column.renderType = 'custom'

    if (column.formatter) {
      const _rootVM = this.getRootVm(column.formatter)
      const formatter = _rootVM[column.formatter]
      if (typeof formatter === 'function')
        column.formatter = formatter.bind(_rootVM)
      else {
        // 尝试构建 column formatter function
        try {
          column.formatter = new Function(`return ${column.formatter}`)
            .apply(this.rootVm)
            .bind(this.rootVm)
        } catch (error) {
          delete column.formatter
        }
      }
    }

    return column
  }

  handleCommand(command) {
    this.$emit('drop-selection-click', command)
  }
}
